import React from "react";
import { Highlight, Prism, themes } from "prism-react-renderer";
import { gatsbyHighlight } from "./codeBlock.module.css";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-csharp");

const CodeBlock = ({ codeString, language, ...props }) => (
  <Highlight code={codeString} language={language} theme={themes.vsDark}>
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <div className={gatsbyHighlight} data-language={language}>
        <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      </div>
    )}
  </Highlight>
);

export default CodeBlock;
