// see: https://www.themoderncoder.com/gatsby-with-prismjs-and-mdx/

import React from "react";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "./src/components/codeBlock";

const preToCodeBlock = (preProps) => {
  if (
    preProps.children &&
    preProps.children.props &&
    preProps.children.type === "code"
  ) {
    const { children, className } = preProps.children.props;
    return {
      codeString: children.trim(),
      language: className && className.split("-")[1],
    };
  }
  return undefined;
};

const components = {
  pre: (preProps) => {
    const props = preToCodeBlock(preProps);
    if (props) {
      return <CodeBlock {...props} />;
    }
    return <pre {...preProps} />;
  },
  wrapper: ({ children }) => <>{children}</>,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
